import React from "react"
import arrow from "../../images/arrow.svg"
import "./style.scss"
import { Link } from "gatsby"

const Decouvrir = ({ to }) => {
    return (
      <Link to={to} className="decouvrir">
        <span>Découvrir</span>
        <img src={arrow} alt="arrow" width="13px" height="12px" className="arrow"/>
      </Link>
    )
}

export default Decouvrir
