import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "./style/projets.scss"
import Decouvrir from "../components/decouvrir"
const Projets = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges,
      slug = pageContext.slug,
      type = slug ? slug.split('/')[1] : null,
      postsFiltered = (type) ? posts.filter(({node}) => node.frontmatter.type === type) : posts;
  return (
    <Layout className={'layout layout-loaded'}>
      <SEO title="Projets"/>
      <div className="masonry-wrapper">
        <div className="masonry">
          {postsFiltered.map(({ node }, index) => {
            return (
              <>
                {(!type || (type && type === node.frontmatter.type)) && (
                  <div className="masonry-item" key={node.fields.slug} data-index={index}>
                    <Link style={{ boxShadow: `none` }} to={`${node.fields.slug}`}>
                      {node.frontmatter.hero && node.frontmatter.hero.childImageSharp && (
                        <>
                          <Img fluid={node.frontmatter.hero.childImageSharp.fluid} alt="image" className="masonry-content" data-sal="slide-up"
                               data-sal-delay="0"
                               data-sal-duration="1000"
                               data-sal-easing="ease"/>
                          <div className="card">
                            <h2 className={node.frontmatter.title.replace(' ', '_')}>{node.frontmatter.title}</h2>
                            <Decouvrir to={`${node.fields.slug}`} />
                          </div>
                        </>
                      )}
                    </Link>
                  </div>
                )}
              </>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Projets

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            type
            hero {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`